<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>자원관리</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="내부교재 출고요청"></CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <div class="dp-table w-100per mb-10">
                                <div class="float-left w-1095px pr-10">
                                    <table class="table-row">
                                        <colgroup>
                                            <col width="150">
                                            <col width="*">
                                            <col width="150">
                                            <col width="*">
                                            <col width="150">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th><span class="txt-red">*</span> 교재정보</th>
                                                <td>
                                                    <input type="text" class="w-100per" placeholder="교재명 입력 후 선택해주세요.">
                                                </td>
                                                <th>수량</th>
                                                <td>
                                                    <input type="text" class="w-100per">
                                                </td>
                                                <th>금액</th>
                                                <td>
                                                    0 원
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="float-right w-105px pl-10">
                                    <button class="btn-default w-95px mt-10">교재추가</button>
                                </div>
                            </div>

                            <div class="dp-table w-100per mb-10">
                                <div class="float-left w-1095px pr-10">
                                    <table class="table-row">
                                        <colgroup>
                                            <col width="150">
                                            <col width="*">
                                            <col width="150">
                                            <col width="*">
                                            <col width="150">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th><span class="txt-red">*</span> 교재정보</th>
                                                <td>
                                                    <input type="text" class="w-100per" placeholder="교재명 입력 후 선택해주세요.">
                                                </td>
                                                <th>수량</th>
                                                <td>
                                                    <input type="text" class="w-100per">
                                                </td>
                                                <th>금액</th>
                                                <td>
                                                    0 원
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="float-right w-105px pl-10">
                                    <button class="btn-default w-95px mt-10">삭제</button>
                                </div>
                            </div>

                            <table class="table-row mt-40">
                                <colgroup>
                                    <col width="150">
                                    <col width="*">
                                    <col width="150">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>요청자</th>
                                        <td>
                                            Amy K(김은지)
                                        </td>
                                        <th>등록일</th>
                                        <td>
                                            2019-00-00
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>출고현황</th>
                                        <td colspan="3">
                                            요청
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>내부/외부</th>
                                        <td colspan="3">
                                            내부
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 용도</th>
                                        <td colspan="3">
                                            <select class="w-200px">
                                                <option value="">선택</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 고객사명</th>
                                        <td colspan="3">
                                            <input type="text" class="w-400px" placeholder="고객사명 입력 후 선택해주세요.">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 과정명</th>
                                        <td colspan="3">
                                            <input type="text" class="w-400px">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 유/무상</th>
                                        <td colspan="3">
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 유상</span></label>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 무상</span></label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>무상 사유</th>
                                        <td colspan="3">
                                            <input type="text" class="w-100per">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>출고희망일</th>
                                        <td colspan="3">
                                            <input type="text" class="w-80px">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>비고<br>(세부내용)</th>
                                        <td colspan="3">
                                            <textarea class="w-100per h-100px"></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <!-- 외부 -->
                            <table class="table-row mt-40">
                                <colgroup>
                                    <col width="150">
                                    <col width="*">
                                    <col width="150">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>출판사</th>
                                        <td colspan="3">
                                            캐럿글로벌
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>배송 주소</th>
                                        <td colspan="3">
                                            강남대로 488 대남빌딩 4층
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>받는 분 성함</th>
                                        <td>
                                            김민정
                                        </td>
                                        <th>연락처</th>
                                        <td>
                                            010-0000-0000
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>언어</th>
                                        <td colspan="3">
                                            영어
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>구입 사유</th>
                                        <td colspan="3">
                                            구입 사유 내용
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>구입거래처</th>
                                        <td colspan="3">
                                            <select class="w-200px">
                                                <option value="">선택</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- //외부 -->

                            <button class="btn-default float-left mt-30">목록</button>
                            <button class="btn-default float-left mt-30 ml-10">삭제</button>
                            <button class="btn-default float-right mt-30">수정</button>
                            <button class="btn-default float-right mt-30 mr-10">출고 완료</button>
                            <button class="btn-default float-right mt-30 mr-10">출고 취소</button>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 


export default {
}
</script>

<style lang="scss" scoped>
</style>